<template>
  <div id="game-load-content" class="col-12 px-0 game-launch mb-5 mb-lg-0"
       :class="{ 'wheel_game': provider === 'WheelOfFortune'}">
    <div ref="gameContainer" class="col-12 col-lg-11 col-xl-11 px-0 mt-3 game-load-block no-gutters gameContainer">

      <div class="row mx-0 mt-2 mt-lg-5">

        <div class="col-11 col-xl-10 mx-auto px-2 px-md-5 py-2 px-md-5 mt-5 mb-5 double-promo-box"
             v-if="provider === 'WheelOfFortune' && (toNextSpin === false || toNextSpin > 0) && userData">
          <WheelCountDown/>
        </div>

        <div v-else class="col-12 col-xl-10 mx-auto px-0 game-cross-block" :class="{ 'wheel_game_block': provider === 'WheelOfFortune'}">
          <div v-if="!loaded || gameClosed" class="row w-100">
            <div class="col-12 game-load-alert d-flex">
              <div v-if="!loaded" class="game-load-content">
                <h6 class="text-center text-white">
                  Идет загрузка игры
                </h6>
                <img :src="require('@/../public/images/logo/main_ru.png')" class="m-auto preview-logo"
                     alt="magnet.bet casino">
              </div>
              <div v-if="gameClosed" class="game-load-content">
                <h5 class="text-center text-white">
                  {{ gameClosedMessage }}
                </h5>
                <img
                    v-if="closingGame"
                    :src="require('@/../public/images/logo/main_ru.png')"
                    class="m-auto preview-logo"
                    alt="loader"
                >
              </div>
            </div>
          </div>
          <div id="game-response-container" class="px-0 game-response-container text-center  mx-auto">
            <div v-if="isFullScreenMode || isMobile" class="row mx-0 buttons-container px-4 pt-2">
              <a
                  id="goHomeBtn" href="#"
                  class="gohome d-md-inline-block mr-3"
                  @click.prevent="goHome">
                <span class="game_action_icon" :style="'background-image: url('+homeIcon+')'"></span>
              </a>
              <a id="minimizeBtn"
                 href="#"
                 class="enableFullScreen d-md-inline-block mr-3" @click.prevent="screenToggle">
                <span class="game_action_icon" :style="'background-image: url('+maximizeIcon+')'"></span>
              </a>
              <a
                  class="text-white" title="Закрыть игру"
                  @click.prevent="closeGame">
                <span class="game_action_icon" :style="'background-image: url('+gameCloseIcon+')'"></span>
              </a>
            </div>
            <div v-if="freeRoundChosen === 1"
                 class="col-12 px-0 freeround-counter-block">
              У Вас <strong id="k" class="text-success"></strong> бесплатных вращений
            </div>

            <iframe
                id="gameLaunchIframe"
                ref="gameLaunchIframe"
                class="gameLaunchIframe"
                :class="provider+'ProvFrame'"
                :src="pregameLaunchUrl()"
                frameborder="0"
                marginwidth="0"
                marginheight="0"
                scrolling="no"
                allowfullscreen
                style="max-width:100%"></iframe>
          </div>
        </div>


        <div v-if="(provider !== 'WheelOfFortune' && provider !== 'Poker') && typeof provider !== 'undefined'"
             id="gameInfoArea"
             class="col-2 d-none d-xl-block pl-0">
          <b-tabs class="game-page-tab game-info-area pb-3 px-0">
            <b-tab class="col-12">
              <template slot="title" class="mx-0">
                <div class="tab-title py-0 col-12 text-center category-item px-0">
                  <button class="btn white-color gray-btn py-2 w-100" name="winners">
                    Победители
                  </button>
                </div>
              </template>
              <div class="winners-tab-body w-100">
                <div class="row mx-0 border-0 px-4 py-2 tab-title-area">
                  <div class="col-2 px-0">
                    <span class="winners-title my-3 font-weight-bold"> # </span>
                  </div>
                  <div class="col-6 px-0">
                    <span class="winners-title my-3 font-weight-bold"> Ник игрока </span>
                  </div>
                  <div class="col-4 px-0">
                    <span class="winners-title my-3 font-weight-bold"> Выигрыш </span>
                  </div>
                </div>
              </div>

              <div class="winners" v-if="winners != null">
                <div
                    v-for="(winner, index) in winners.all"
                    :key="'winner_'+index"
                    class="row mx-0 winner py-3 px-2 text-center"
                    :class="[index % 2 ? 'light-bg' : '']">
                  <div class="col-2 px-0">
                    <span class="gray-color font-weight-bold">{{ index + 1 }}</span>
                  </div>

                  <div class="col-6 px-0">
                    <div class="row mx-0">
                      <span class="gray-color font-weight-bold">{{ winner.user }}</span>
                    </div>
                  </div>

                  <div class="col-4 px-0">
                    <span class="gray-color font-weight-bold">
                      {{ winner.total_profit ? winner.total_profit : winner.amount }} Руб
                    </span>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab class="col-12" active>
              <template slot="title" class="mt-2 mx-0">
                <div class="tab-title  col-12 text-center category-item px-0">
                  <button class="btn btn-block red-btn py-2" name="games">
                    Игры
                  </button>
                </div>
              </template>
              <div class="game-tab-body w-100">
                <div class="row mx-0 border-0 px-1 tab-title-area ">
                  <span class="game-rating-title my-3"> Рейтинг популярных игр: </span>
                </div>

                <div class="games">
                  <div
                      v-for="(mgame, index) in games" :key="'game_'+index"
                      class="row mx-0 game py-2 px-2"
                      :class="[index % 2 ? 'light-bg' : '']"
                      @click.prevent.stop="gotoGameView(mgame)">
                    <div class="col-2 index-area px-2">
                      <span class="list-index gray-color font-weight-bold">{{ index + 1 }}</span>
                    </div>
                    <div class="col-10 h-100 pl-2 pr-1">
                      <div class="row h-100 mx-0">
                        <div class="col-6 h-100 d-flex px-2">
                          <img :src="getImageUrl(mgame)" class="game-img mh-100 m-auto" alt="loader">
                        </div>
                        <div class="col-6 d-flex h-100 px-0">
                          <span class="text-white game-title my-auto">
                            {{ mgame.name }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>

        <div v-if="!isFullScreenMode && !isMobile" class="mx-0 buttons-container p-0 buttons-vertical-container">
          <div class="game-action-area" v-if="!(toNextSpin === false || toNextSpin > 0) && userData">
            <a
                id="fullScreenBtn"
                href="#"
                class="enableFullScreen d-none d-md-inline-block" title="Полноэкранный режим"
                @click.prevent="screenToggle">
              <span class="game_action_icon" :style="'background-image: url('+maximizeIcon+')'"></span>
            </a>
          </div>



          <div v-if="provider ==='WheelOfFortune' && (toNextSpin === true || toNextSpin < 0)" class="game-action-area">
            <router-link class="d-none d-md-inline-block" :to="{ name: 'home' }" >
              <span class="game_action_icon" :style="'background-image: url('+gameCloseIcon+')'"></span>
            </router-link>
          </div>
          <div v-else-if="provider ==='WheelOfFortune' && (toNextSpin === false || toNextSpin > 0)">
          </div>
          <div v-else class="game-action-area">
            <a class="text-white d-none d-md-inline-block" title="Закрыть игру" @click.prevent="closeGame">
              <span
                  class="game_action_icon"
                  :style="closingGame ? '' : 'background-image: url('+gameCloseIcon+')'">
                <i v-if="closingGame" class="fa fa-spin fa-spinner"></i>
              </span>
            </a>
          </div>

        </div>
      </div>

      <div>
        <SuperomaticSelecter
            v-if="providersWithDenomination.includes(provider) && !restoring"
            :game="game"
            @superoNominationSelected="superoNominationSelected">
        </SuperomaticSelecter>
      </div>
    </div>
  </div>
</template>

<script>
import {scroller} from 'vue-scrollto/src/scrollTo'
import Config from '@/config'
import SuperomaticSelecter from './SuperomaticSelecter'
import axios from 'axios'
import WheelCountDown from "@/components/main/WheelCountDown.vue";
const secondScrollTo = scroller()

export default {
  name: 'LaunchGame',
  props: ['game', 'provider', 'gameName', 'gameId', 'restoring', 'freeRoundAnswer', "isDemo"],
  data() {
    return {
      isLandscape: false,
      response: {status: 'loading'},
      gameCloseIcon: require('@/../public/images/icons/close_icon.png'),
      maximizeIcon: require('@/../public/images/icons/maximzie.svg'),
      homeIcon: require('@/../public/images/icons/home_icon.svg'),
      wheelBg: require('@/../public/images/game_bg/wheel_bg.png'),
      gameBg: '',
      isFullScreenMode: false,
      superomaticNomination: null,
      loaded: false,
      launchUrl: '',
      closingGame: false,
      gameClosed: false,
      gameClosedMessage: '',
      userCanChooseFreeRound: false,
      providersWithFreeRounds: null,
      freeRoundData: null,
      freeRoundChosen: false, // false - no choice,1 - yes, 2 - no
      providersWithDenomination: ['superomatic', 'superomaticv2'],
      startedFreeRoundInfo: null,
      roundInfoInterval: null,
      logoPreview: false,
      gameLaunchMobileResponse: null
    }
  },
  components: {
    WheelCountDown,
    SuperomaticSelecter
  },
  created() {
    let settings = this.$store.getters.getMainSettings
    if (typeof settings !== "undefined") {
      if (settings.hasOwnProperty("freeRoundModules") && settings.freeRoundModules) {
        this.providersWithFreeRounds = settings.freeRoundModules
        if (!this.restoring) {
          this.userCanChooseFreeRound = true
          this.userHasFreeRound()
        }
      }
    }
  },

  computed: {
    toNextSpin() {
      return this.$store.getters.getMainSettings['timeToWheelNextSpin']
    },
    wheelcondition() {
      return this.$store.getters.userData['wheelcondition']
    },
    isMobile() {
      return this.$_config.environment.mobile
    },
    userData() {
      return this.$store.getters.userData
    },
    games() {
      return this.$store.getters.topGames
    },
    winners() {
      return this.$store.getters.getHomepageWinnersList
    },
    gameCloseUrl() {
      return Config.baseUrl + '/Api/CloseGame/' + this.provider
    },
    freeRoundProviders() {
      let settings = this.$store.getters.getMainSettings

      if (typeof settings !== "undefined") {
        if (settings.hasOwnProperty("freeRoundModules") && settings.freeRoundModules) {
          return settings.freeRoundModules
        }
      }

      return false
    }
  },
  methods: {
    userHasFreeRound() {
      if (!this.game || !this.provider) {
        this.userCanChooseFreeRound = false
        return false
      }

      if (!this.freeRoundProviders) {
        this.userCanChooseFreeRound = false
        return false
      }

      if (!this.freeRoundProviders.includes(this.provider)) {
        this.userCanChooseFreeRound = false
        return false;
      }

      axios.post(
          Config.baseUrl + '/Api/checkUserFreeRoundNew',
          {game: this.gameId, provider: this.provider},
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
      ).then(({data}) => {
        if (data.status === 'ok' && data.data) {

          this.freeRoundData = data.data
          this.userCanChooseFreeRound = true
          return true
        }
      }).catch(err => {
        this.userCanChooseFreeRound = false
        console.log(err.message)
      })

      this.userCanChooseFreeRound = false
      return true;
    },
    pregameLaunchUrl() {
      setTimeout(() => this.logoPreview = true, 2000);
      if (this.logoPreview) {
        if (!this.isMobile) {
          return this.gameLaunchUrl()
        } else {
          if (!this.provider || this.provider.toLowerCase() !== 'wheeloffortune') {
            this.gameLaunchMobile()
            if (this.gameLaunchMobileResponse) {
              return this.gameLaunchUrl()
            }
          } else {
            return this.gameLaunchUrl()
          }
        }
      }
    },
    gameLaunchMobile() {
      this.axios.get(
        this.gameLaunchUrl()
      ).then(({ data }) => {
        if (data instanceof Object) {
          if (data.status) {
            if (data.status === "ok") {
              window.location.href = data.data.url
              setTimeout(() => {
                this.$router.push({name: 'home'})
              }, 3000)
            }
          } else {
            this.gameLaunchMobileResponse = data
          }
        } else {
          this.gameLaunchMobileResponse = data
        }
      });
    },

    gameLaunchUrl() {
      if (!this.provider || typeof this.provider === 'undefined' || typeof this.gameId === 'undefined') {
        this.$root.$emit('modalAlertMessage', {msg: 'Что-то пошло не так. Попробуйте снова #1', status: 'error'})
        return ''
      }

      let launchUrl = [
        Config.baseUrl,
        '/Api/LoadGame/',
        this.provider,
        '/',
        this.gameId ? this.gameId : '',
      ];

      /** case when user restore active session */
      if (this.restoring) {
        this.launchUrl = launchUrl.join('')
        return this.launchUrl
      }
      /** game provider has no free round and has no denomination (alexa,outcombetv1) */
      if (!this.freeRoundProviders.includes(this.provider) && !this.providersWithDenomination.includes(this.provider)) {
        this.launchUrl = launchUrl.join('')

        return this.launchUrl
      }

      if (this.isDemo == 0) {
        launchUrl.push("/demo")
        this.launchUrl = launchUrl.join("")
      }

      /** game provider has denomination , cases - provider has no free rounds or user has no available free round  */
      /** game provider has free round (outcombetv2,superomaticv2) */
      if (this.freeRoundProviders.includes(this.provider)) {
        /** user has made choice play free or not */
        if (this.freeRoundChosen) {

          /** by default we send roundId=0,if user has free round,but dont wont to play free */
          let roundId = 0;

          if (this.freeRoundChosen === 1 && this.freeRoundData) {
            /** user choose play free round */
            roundId = this.freeRoundData.id
          }

          if (this.freeRoundChosen === 2 && this.providersWithDenomination.includes(this.provider)) {

            /** case superov2 ,when user has free round but choose not to play free */
            if (this.superomaticNomination) {
              launchUrl.push('?gamelaunchData%5Bnomination%5D=' + this.superomaticNomination)
              this.launchUrl = launchUrl.join('')
              return this.launchUrl
            }

            this.$root.$emit('bv::show::modal', 'superomaticModal')
            return ''
          }

          launchUrl.push('?gamelaunchData%5BroundId%5D=' + roundId)
          this.launchUrl = launchUrl.join('')
          return this.launchUrl
        }

      }

      if (this.providersWithDenomination.includes(this.provider)) {
        if (this.superomaticNomination) {
          launchUrl.push('?gamelaunchData%5Bnomination%5D=' + this.superomaticNomination)
          this.launchUrl = launchUrl.join('')
          return this.launchUrl
        }
        if (!this.userCanChooseFreeRound) {
          this.$root.$emit('bv::show::modal', 'superomaticModal')
          return ''
        }
      }
      return launchUrl.join('');
    },

    superoNominationSelected(value) {
      this.superomaticNomination = value
    },
    userFreeRoundChoice(value) {
      this.freeRoundChosen = value
      this.userCanChooseFreeRound = false
    },
    getImageUrl(game) {
      return Config.storageUrl + '/' + game.picture.replace(/^\/|\/$/g, '')
    },
    containerSize() {
      try {
        const availWidth = document.querySelector('.game-cross-block').offsetWidth
        const koef = 1.8
        const availHeight = availWidth / koef
        const sizes = {
          width: availWidth,
          height: availHeight
        }
        return sizes
      } catch (err) {
        console.log(err.message)
      }
    },
    screenToggle() {
      if (document.fullscreenElement) {
        this.closeFullscreen()
      } else {
        this.fullscreen()
      }
    },
    goHome() {
      return this.$router.push({ name: 'home' })
    },
    fullscreen() {
      const framesDiv = document.querySelector('#game-response-container')
      if (document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled) {
        if (framesDiv.requestFullscreen) {
          framesDiv.requestFullscreen()
          framesDiv.classList.add('game-maximized')
          this.isFullScreenMode = true
        } else if (framesDiv.webkitRequestFullscreen) {
          framesDiv.webkitRequestFullscreen()
          framesDiv.classList.add('game-maximized')
          this.isFullScreenMode = true
        } else if (framesDiv.mozRequestFullScreen) {
          framesDiv.mozRequestFullScreen()
          framesDiv.classList.add('game-maximized')
          this.isFullScreenMode = true
        } else if (framesDiv.msRequestFullscreen) {
          framesDiv.msRequestFullscreen()
          framesDiv.classList.add('game-maximized')
          this.isFullScreenMode = true
        }
        if (framesDiv.webkitExitFullscreen) {
          framesDiv.webkitExitFullscreen()
        }
      }
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
      this.isFullScreenMode = false
    },
    async gotoGameView(gg) {

      this.loaded = false
      this.logoPreview = false
      await axios.get(this.$_config.closeGameUrl + this.provider,
      ).then(async ({ data }) => {
        this.loaded = false
        if (data.status) {
          await this.$store.dispatch('fetchUserData')

          if (data.status === "ok") {
            this.$router.push({
              name: 'LaunchGame',
              params: {
                game: gg,
                provider: gg.provider,
                gameId: gg.id,
                gameName: gg.name,
                restoring: false,
              }
            })
          }
        }
      });
    },
    setIframeSizes() {
      let container = document.querySelector('.game-cross-block')
      if (!container) {
        return
      }
      let sizes = {
        width: container.offsetWidth,
        height: parseInt(container.offsetWidth / 1.5)
      }
      if (this.isFullScreenMode) {
        container = document.querySelector('#game-response-container')
        sizes = {
          width: container.offsetWidth,
          height: window.innerHeight - 35
        }
      }
      if (this.provider === "alexa") {
        sizes = {
          width: parseInt(container.offsetWidth),
          height: parseInt(window.innerHeight),
        }
      }

      if (this.isLandscape && this.isMobile) {
        sizes = {
          width: container.offsetWidth,
          height: window.innerHeight - 35
        }
      }

      document.querySelector('.gameLaunchIframe').style.width = sizes.width + 'px'
      document.querySelector('.gameLaunchIframe').style.maxWidth = sizes.width + 'px'
      document.querySelector('#game-response-container').style.width = sizes.width + 'px'
      document.querySelector('#game-response-container').style.maxWidth = sizes.width + 'px'

      if (typeof this.provider !== 'undefined' && this.provider.toLowerCase() !== 'wheeloffortune') {
        document.querySelector('.game-info-area').style.height = sizes.height + 'px'
        document.querySelector('.gameLaunchIframe').style.height = sizes.height + 'px'
        document.querySelector('.gameLaunchIframe').style.maxHeight = sizes.height + 'px'
        document.querySelector('#game-response-container').style.height = sizes.height + 'px'
        document.querySelector('#game-response-container').style.maxHeight = sizes.height + 'px'
      } else {
        document.querySelector('.gameLaunchIframe').style.height = '100%'
        document.querySelector('.gameLaunchIframe').style.minHeight = '500px'
        document.querySelector('#game-response-container').style.height = '100%'
        document.querySelector('#game-response-container').style.minHeight = '500px'
      }
    },
    setOrientationOnChangeEvent() {
      window.addEventListener('resize', () => {
        setTimeout(() => {
          this.isLandscape = window.innerWidth > window.innerHeight
          this.setIframeSizes()
        }, 20)
      })
    },
    closeGame() {
      this.closingGame = true
      const url = this.gameCloseUrl
      this.axios.get(url).then((answer) => {
        this.$refs.gameLaunchIframe.setAttribute('src', '')
        if (answer.data) {
          const data = answer.data
          this.gameClosed = true
          if (data.status) {
            const message = data.msg
            if (data.status === 'error') {
              this.$root.$emit('modalAlertMessage', { msg: message, status: data.status })
              this.$root.$emit('bv::show::modal', 'modalAlert')
            }
            this.$store.dispatch('fetchFlashMessages')
            setTimeout(() => {
              this.gameClosedMessage = message
              this.closingGame = false
              setTimeout(() => {
                return this.$router.push({ name: 'home' })
              }, 500)
            }, 1000)
          } else {
            this.closingGame = false
            this.$store.dispatch('fetchFlashMessages')
            this.$root.$emit('modalAlertMessage', { msg: 'Произошла ошибка.Обратитесь к техподдержке', status: 'error' })
            this.$root.$emit('bv::show::modal', 'modalAlert')
            return this.$router.push({ name: 'home' })
          }
        } else {
          this.gameClosed = true
          this.closingGame = false
          this.$store.dispatch('fetchFlashMessages')
          this.$root.$emit('modalAlertMessage', { msg: 'Произошла ошибка.Обратитесь к техподдержке', status: 'error' })
          this.$root.$emit('bv::show::modal', 'modalAlert')
          return this.$router.push({ name: 'home' })
        }
      }).catch(err => {
        if (err) {
          this.closingGame = false
          this.$root.$emit('modalAlertMessage', { msg: err.message(), status: 'error' })
          this.$root.$emit('bv::show::modal', 'modalAlert')
          this.$store.dispatch('fetchFlashMessages')
          return this.$router.push({ name: 'home' })
        }
      })
      this.$store.dispatch('checkUserFreeRound')
      this.$store.dispatch('checkForAliveGame')
    }
  },
  mounted() {

    setTimeout(() => {
      if (!this.userData) {
        return this.$router.push({ name: 'login' })
      }
    }, 2000)
    if (typeof this.provider === 'undefined' || typeof this.gameId === 'undefined') {
      this.$root.$emit('modalAlertMessage', { msg: 'Что-то пошло не так. Попробуйте снова #2', status: 'error' })
      this.$root.$emit('bv::show::modal', 'modalAlert')
      return this.$router.push({ name: 'home' })
    }


    this.isLandscape = window.innerWidth > window.innerHeight
    this.setOrientationOnChangeEvent()
    this.setIframeSizes()
    if (typeof document.querySelector('.gameLaunchIframe') !== 'undefined' &&
      document.querySelector('.gameLaunchIframe')) {
      document.querySelector('.gameLaunchIframe').onload = () => {
        this.loaded = true
      }
    }

    const gamesLoadParams = {
      provider: this.provider,
      gameId: this.gameId
    }

    this.$store.dispatch('fetchGameWinners', gamesLoadParams)
    this.$store.dispatch('fetchTopGames')

    setTimeout(() => {
      secondScrollTo('#game-load-content')
    }, 600)

    const fullScreenBtn = document.querySelector('#fullScreenBtn')

    document.addEventListener('webkitfullscreenchange', this.fullscreenChange)
    document.addEventListener('mozfullscreenchange', this.fullscreenChange)
    document.addEventListener('fullscreenchange', this.fullscreenChange)
    document.addEventListener('MSFullscreenChange', this.fullscreenChange)
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        fullScreenBtn.innerHTML = '<i class="fa fa-window-minimize"></i>'
      } else {
        fullScreenBtn.innerHTML = '<span class="game_action_icon" style="background-image: url(' + this.maximizeIcon + ')"></span>'
      }
    })

    if (document.getElementById('game-response-container')) {
      document.getElementById('game-response-container').addEventListener('fullscreenchange', () => {
        if (document.fullscreenElement) {
          fullScreenBtn.innerHTML = '<span class="game_action_icon" style="background-image: url(' + this.maximizeIcon + ')"></span>'
        } else {
          const frame = document.querySelector('#game-response-container')
          frame.style.height = this.containerSize().height + 'px'
          fullScreenBtn.innerHTML = '<span class="game_action_icon" style="background-image: url(' + this.maximizeIcon + ')"></span>'
        }
      })
    }
    if (this.freeRoundAnswer) {
      this.userFreeRoundChoice(this.freeRoundAnswer)
    }
  },
  watch: {
    isLandscape() {
      this.isLandscape = screen.height < screen.width
    },
    restoring() {
      if (this.restoring) {
        this.$root.$emit('bv::hide::modal', 'activeAliveGameModal')
      }
    }
  }
}

</script>

<style>
.freeround-counter-block {
  height: 30px;
}

.game_action_icon .fa-spinner {
  color: #ffffff;
  font-size: 17px;
}

#game-load-content {
  min-height: 100vh;
}

.game-launch .game-load-content {
  width: 100%;
  height: 200px;
  margin: auto;
}

#game-reload-icon>i {
  color: #808080;
  font-size: 20px;
  cursor: pointer;
}

.buttons-vertical-container {
  position: absolute;
  right: -25px;
  top: 0;
  min-height: 200px;
  width: 40px;
}

.game-action-area {
  background-color: #151515;
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  text-align: center;
  vertical-align: center;
  display: flex;
}

.game-action-area a {
  height: 18px;
  margin: auto;
  display: flex;
}

.index-area {
  display: flex;
  height: 100%;
}

#WheelGameContainer {
  max-height: 100% !important;
}

.game_action_icon {
  width: 17px;
  height: 17px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.winner,
.game-title {
  font-size: 14px;
}

.gameLaunchIframe {
  height: 100%;
}

.enableFullScreen {
  background-color: transparent !important;
  border: none !important;
}

.buttons-container {
  justify-content: flex-end;
  height: 35px;
}

.game-page-tab .tab-pane {
  padding: 0 !important;
}

.game-page-tab .nav-tabs {
  border: none !important;
  justify-content: center !important;
}

.game-page-tab .nav-link.active {
  background-color: transparent !important;
  border: none !important;
}

.game-page-tab .nav-link {
  border: none !important;
  padding: 0 !important;
}

.game-page-tab .nav-item {
  width: 50%;
}

.game-page-tab .nav-link.active .game-tab-btn {
  background: rgb(254, 138, 17) !important;
  background: linear-gradient(0deg, rgba(254, 138, 17, 1) 3%, rgba(235, 96, 16, 1) 52%, rgba(216, 51, 15, 1) 96%) !important;
}
</style>

<style scoped>
.double-promo-box {
  border-radius: 7px;
  box-shadow: 0 0 15px 0 #ffffffbf;
}

.gameLaunchIframe {
  height: 100%;
  width: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
}

.close-btn {
  background: rgb(254, 138, 17) !important;
  background: linear-gradient(0deg, rgba(254, 138, 17, 1) 3%, rgba(235, 96, 16, 1) 52%, rgba(216, 51, 15, 1) 96%) !important;
  border-radius: 20px;
}

.gameContainer {
  height: 100%;
}

.tab-icon {
  height: 35px;
  width: auto;
}

.tab-title-area {
  border-top: 1px solid #10131c;
  border-bottom: 1px solid #10131c;
  color: #565759;
}

.game-info-area .tab-content {
  overflow-y: scroll;
  height: 100%;
  max-height: 100%;
}

.game-info-area::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

.game-info-area::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 7px;
}

.light-bg {
  background-color: #202121;
}

.game {
  cursor: pointer;
  height: 85px;
}

.list-index {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
}

.game-img {
  border-radius: 3px;
  height: auto;
  max-height: 100%;
  width: 100%;
  object-fit: contain;
}

.game-rating-title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.winners-title {
  font-size: 12px;
}

.game-tab-btn:hover {
  box-shadow: 0 0 20px rgba(207, 83, 27, 0.6) !important;
}

.game-info-area {
  background-color: rgba(11, 14, 21, 0.6);
  overflow-x: hidden;
}

.game-tab-btn {
  width: 100%;
  background: #1b2026 !important;
  border: none !important;
  border-radius: 20px;
}

.game-load-block {
  width: 100%;
  min-height: fit-content !important;
  background-repeat: no-repeat;
  margin: 0 auto !important;
}

.game-launch .game-load-block .game-load-alert {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  margin-left: 0;
  text-align: center;
  min-height: 200px;
  height: 100%;
  background-color: #151515;
}


.game-launch .game-load-block .game-load-alert .alert {
  min-height: 200px;
  align-items: center !important;
  justify-content: center;
  display: flex !important;
  padding: 0 !important;
}

#app.mobile .page-content.main-block {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.resp-loader.loading {
  max-width: 300px;
  max-height: 300px;
  top: unset;
  left: unset;
}

.landscape-close-btn {
  top: 10px;
}

#goHomeBtn .game_action_icon {
  width: 20px;
}

.preview-logo {
  width: 100%;
  max-width: 350px;
}
.wheel_game_block{
  min-height: 800px;
  max-height: 90vh;
}

@media screen and (max-width: 760px) {
  .preview-logo {
    width: 100%;
    max-width: 120px;
  }

  .game-hint-text {
    font-size: 12px;
  }
}
</style>
